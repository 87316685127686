
.custom-table-div{
    margin-top:5%;
    background-color:white;
    border-radius:8px;
    border:1px solid  #ccd7df;
    min-width:400px;
    padding:10px;
}

.custom-table-row{
    border-top: solid 1px #ccd7df;
    width: 100%;
}

.custom-table{
    width:100%;
}

.custom-table td{
    padding:10px;
}

/* Table ends here */

#status{
    font-size: 26px;
    font-style: italic; 
}


.summary{
    /* text-align: center; */
    width: 80%;
    margin: auto;
}

.summary h2{
    font-size: 20px;
    font-weight: bold;
    color:#445566;
}

.info{
    padding: 20px
}

.info p{
    font-size: 18px;
    color:#445566;
}


.job-listings-page{
    padding:20px;
}

.job-listings-page-button2{
    display: none;
}

.job-listings-page .job-listings-account-page-button1{
    display: none;
}



@media (max-width: 900px) { 
    .job-listings-page-button1{
        display: none;
    }
    .job-listings-page-button2{
        display: block;
        margin-top: 20px;
        text-align: center;
    }
    .custom-table-div{
        width: 100%;
    }
}


@keyframes offer-animation {
    0% { height: 0;}
    100% { height: 64px;}
}


.new-candidate-card{
  margin-top: 20px;
  height: 64px;
  border-radius: 8px;
  background-color: #0099ee;
  color:#ffffff;
  font-size:16px;
  font-weight: bold;
  animation: offer-animation 0.6s;
}

.new-candidate-card-text{
  margin-top: 8px;
  padding: 10px;
}

.new_candidate-view-button{
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  float:right;
  padding:10px;
}

.align-left-row td{
    text-align: left ;
}
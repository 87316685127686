.offer-detail-div{
    border-radius:9px;
    background-color:#ffffff;
    width:50%;
    max-width:500px;
    margin:auto;
    padding:20px;
    min-width:400px;
    margin-top: 50px;
}


.offer-detail-time-circle{
    border-radius: 50%;
    width: 60px;
    height: 60px;
    /* padding: 18px 9px 18px 10px; */
    /* object-fit: contain; */
    border: solid 1px #ffffff;
    background-color: #115e74;
    text-align: center;
    color:#ffffff;
    padding-top: 15px;
    margin-left: 42%;
    margin-top: -10%;
    
}

@media (max-width: 700px) { 
    .offer-detail-div{
        width:100%;
        /* max-width: 800px; */
    }

}
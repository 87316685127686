
#nav{
	padding-left: 100px;
	text-decoration: none;
	color: rgb(181, 181, 181);	
}

@keyframes top-bottom {
    0% { margin-top: -50px; };
    100% { margin-top: 0px; };
}

@keyframes left-right {
    0% { margin-left: -50px; }
    100% { margin-left: 0px; };
}

.navbar-expand{
    animation: top-bottom 1s;
}

.bg-primary{
	background-color: rgb(37, 51, 67) !important;
}

.color-nav{
	color: rgb(181, 181, 181);

}
.color-nav a{
    color: #FFFFFF;
}


.dropdown a{
	color: black;
}

@media (max-width: 800px) {
	#nav{
		color: #FFFFFF;
	}

	#bignav button{
		font-size: 14px;
	}
	#bignav{
		text-align: center;
		min-width: 500px;
		color: rgb(181, 181, 181);
	}
}

.sidephoto {
    margin: 0;
    padding: 0;
    width: 30%;
    min-width: 400px;
    position: fixed;
    height: 100%;
    filter: brightness(60%);
    background-color: rgba(8, 36, 76, 0.5);
}
  

.sidebar {
    animation: left-right 1s;
    height: 100vh;
}
  
.sidebar a {
    display: block;
    color: #fff;
    margin-top: 20px;
    padding-left: 10px;
    text-decoration: none;
    font-size: 14px;
}

.sidebar .active span {
    background-color: rgb(73, 154, 243) !important;    
    border-top-right-radius: 50px 20px;
    border-bottom-right-radius: 50px 20px;
    transition: 0.5s all;
    padding: 20px;
    padding-left: 0px; 
    padding-right: 30px;
    animation: menu-open 1s;
}
  
.sidebar a:hover:not(.active) {
    color: white;
}
  
@media screen and (max-width: 400px) {
    .sidebar a {
        text-align: center;
        float: none;
    }
}

html{
  max-width: 2160px;
  margin: auto;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(249,249,249);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.logged-in-container{
  width:80%;
  margin:auto;
  padding:20px;
  /* margin-top:55px; */
}

.option-button{
  width:50%;
  height:48px;
  border-radius: 4px;
  border:solid 1px #a3bbca;
  background-color: #ffffff;
}

/* Prev and next buttons for company and job listing */

.btn{
  height: 48px;
  border-radius: 4px;
  border: none;
}


.btn-primary{
  background-color:#249afa;
  color: #ffffff;
}

.btn-primary:hover{
  background-color: #007add;

}

.btn-success{
  background-color: #60c1b1;  
  color: #ffffff;
}

.btn-light{
  background-color:rgb(249,249,249);
  color: #839eaf;
}

.btn-danger{
  background-color:rgba(197,94,93);
  color: #fff;
}

.btn-outline-primary{
  outline-style: dotted;
  border: 1px #249afa;
}

.form-width-size{
  width: 500px;
}

.text-secondary{
  color: #445566 !important;
}

.invalid-feedback {
  display: block;
}

.form-control{
  height: 48px !important;
}

.progress-bar{
  background-color: #445566 !important;
}

.text_input__group{
  margin-top: 15px;
}

.text-input-label{
  font-size: 16px;
  margin: 2px;
  font-weight: bold;
  margin-bottom: 10px;
  color:#36454f;
}





@media (max-width: 700px) { 
    .form-width-size{
      width: 100%;
    }
}

/* Show and hide password */
.pwd-container {
  position: relative;
}

.pwd-container .icon {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 4px;
}

.landing-nav{
  float: left;
}
.landing-nav a{
  color:white;
  padding-right:40px;
  font-weight:bold;
  text-align:center;
}
@media (max-width: 800px) { 
  .landing-nav a{
    display: none;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.profile-pic{
  border-radius: 50%;
  max-width:115px;
  max-height:48px;
  width: auto;
  height: auto;
}
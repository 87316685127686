.company-preview-form{
    border-radius: 4px;
    border:solid 1px #ccd7df;
    background-color:#ffffff;
    margin:auto;
    padding:20px;
    width:500px; 
}

.company-preview-label{
    margin:20px;
    font-size: 16px;
    color:#445566;
    margin-bottom:5px;
}

.company-preview-value{
    color: #839eaf;
    font-size: 16px;
    line-height: 1.5;
    margin-left:20px;
}

.company-edit-form{
    width:500px;
    margin:auto;
    padding:20px;
    border:solid 1px #ccd7df;
    background-color: #ffffff;
    border-radius: 4px;

}

.company-create-div{
    width:500px;
    margin:auto;
}


@media (max-width: 800px) {
    .company-preview-form {
        width: 100%;
    }
    .company-edit-form{
        width: 100%;
    }
    .company-create-div{
        width: 100%;
    }
}
.candidates-div-list{
    text-align:center;
    width: 600px;
    margin:auto;
    padding:20;
}

.candidates-div-list-header{
    font-size: 20px;
    color: #445566;
    font-weight: bold;
}

.candidates-div-list-warning{
    color: #839eaf;
    font-size: 14px;
    margin-top: 20px;
}

.candidates-div-list-candidates{
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    overflow-x: scroll;
}

.candidates-div-list-candidate{
    /* position: relative; */
    max-width: 300px;
    margin: auto;
    border: 2px solid #334455;
    border-radius: 4px;
    display: flex;
    margin-bottom: 10px;
}

.candidates-div-list-candidate:hover{
    background-color: #b5b8bb;
}


.candidates-div-list-candidate img{
    width: 40px;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
    background-color: #bbbec0;
    padding:5px;
}

.candidates-div-list-candidate-name{
    font-weight: bold;
    color: #334455;
    margin-top: 10px;
    font-size: 24px;
}


@media  (max-width: 800px) { 
    .candidates-div-list{
        width:100%;
    }
    /* .candidates-div-list-candidate img{
        width: 80%;
    } */
    .candidates-div-list-candidates{
        display: block;
    }
}

#flex-container{
    display: flex;
}

#flex-div{
    flex:1;
    border-radius: 8px;
    border: solid 1px #ccd7df;
    background-color: #ffffff;
    height:400px;
    margin:20px;
    min-width: 400px;
}


#flex-div h3{
    color: #445566 ;
    padding:20px;
    font-size: 16px;
}


@media (max-width: 1200px) { 
    #flex-container{
        display: block;
        /* margin:0; */
    }
    #flex-div{
        margin: auto;
        max-width: 500px;
        margin-bottom: 30px;
    }

}
.text-secondary.legal-agreements {
    line-height: 2.5rem;
}

.text-secondary.legal-agreements:hover {
    background: #7481912e;
}

a.legal-agreements:hover{
    text-decoration: none!important;
    
}
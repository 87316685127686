.button__normal{
    width: 280px;
    height: 48px;
    padding: 12px 0;
    border-radius:4px;
    border: solid 1px #a3bbca;
    background-color:#249afa;
    text-align:center;
}

.button__menu{
    margin-left:5px;
    border-radius: 18px;
    border: solid 2px #839eaf;
    background-color: #ffffff;
    padding: 6px 15px;
    color:#839eaf;
    font-weight: 600;
}




@media (max-width: 800px) {
  
}
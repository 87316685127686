.job-opening-table{
    width: 500px;
    margin: auto;
}

.job-opening-add-div{
    margin:auto;
    padding:20px;
    max-width:500px;
}

@media (max-width: 900px) { 
    .job-opening-table{
        width: 100%;
    }
    .logged-in-container{
        width: 100%;
    }
}



.home-tab-nav{
    width: 263px;
    border: solid 1px #ccd7df;
    background-color:#ffffff;
    float:left;
}

.home-tab {
    width:400px;
    border-radius: 4px;
    border: solid 1px #ccd7df;
    background-color:#ffffff;
    width:400px;
    margin:auto;
    float: left;
    margin-left: 20px;
}

.home-tab-text{
    height: 24px;
    font-size: 16px;
    line-height: 1.5;
    color: #36454f;
    margin:10px;
    cursor: pointer;
}
        
@media (max-width: 1200px) { 
    
    .home-tab-nav{
        width: 100%;
        margin-bottom: 20px;
        float: none;
        max-width: 600px;
    }
    .home-tab{
        width: 100%;
        margin: auto;
        float: none;
        max-width: 600px;
    }

}
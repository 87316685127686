.resume-item-page{
    width:80%;
    margin:auto;
    padding:20;
    max-width: 500px;
    text-align: center;
}

.resume-item-page-form{
    border: solid 1px #ccd7df;
    background-color: #ffffff;
    margin:auto;
    padding:20px;
    border-radius: 10px;
    max-width:560px;
    margin-top:20px;
    text-align: left;
}

.resume-item-page-added{
    border: solid 1px #ccd7df;
    background-color: #ffffff;
    margin:auto;
    padding:20px;
    width: 500px;
    border-radius: 10px;
    max-width:560px;
    margin-top:20px;
    text-align: left;
}

.resume-preview-container{
    margin:auto;
    padding:20;
    max-width:500px;
}

.resume-item-page-added-image{
    border: solid 1px #ccd7df;
    background-color: #ffffff;
    margin:auto;
    width: 500px;
    border-radius: 10px;
    max-width:560px;
    height: 120px;
    margin-top:20px;
    text-align: left;
}
.preview-candidate-img{
    width: 80px;
    height: 80px;
    float:left;
    border-radius: 10px;
    margin:20px;
}

.resume-item-page-added-image p{
    margin-top: 6%;
}

@media (max-width: 900px) { 
   
    .resume-item-page{
        width: 100%;
        max-width:none;
    }
    .resume-item-page-added{
        width:100%;
        font-size: 14px;
    }

    .resume-item-page-added-image{
        width: 100%;
    }

}
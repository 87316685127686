.rating-div{
    width:500px;
    margin:auto;
    padding:4px;
    margin-top:55px;
    /* text-align: center; */
}

.rating-div-container{
    margin-top: 40px;
    text-align: center;
}

.rating-div-table{
    margin-top: 40px;
    width: 500px;
}

.rating-div-table td{
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    color: #445566;
    margin:10px;
}

.rating-div-button{
    border:none;
    width:500px;
    height: 70px;
    margin-top: 30px;
    background-color: #0099ee;
    color: antiquewhite;
    border-radius: 8px;
    font-weight: bold;
}

.rate-td{
    text-align: right;
    float: right;
}

.rating-div-header{
    font-size: 24px;
    font-weight: bold;
    color: #445566;
}


@media (max-width: 800px) {
    .rating-div-table{
        width: 100%;
        padding: 5%;
    }
    .rating-div{
        width: 100%;
    }
    .rating-div-table td{
        font-size: 14px;
    }
    .rating-div-button{
        width: 100%;
    }
    .rate-td{
        float: right;
    }
}

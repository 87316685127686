.text-input, .text-input input{
    border-radius: 4px;
    border: solid 1px #ccd7df;
}

.normal-size-input{
    padding: 2px 8px;
    height: 40px;
    width: 100%;
}

.profile-edit-div{
    width:500px;
    margin:auto;
    padding:20px;
    background-color: #ffffff;
    border: solid 1px #ccd7df;
    border-radius: 8px;
}

.profile-create-div{
    width: 500px;
    margin:auto;
}

.profile-question-div{
    margin-top: 40px;
}

.profile-question-div p{
    color: #36454f;
    font-weight: bold;
    font-size: 16px;
}

.profile-create-header{
    color: #36454f;
    font-weight: bold;
    font-size: 20px;
}

@media (max-width: 900px) { 
    .profile-edit-div{
        width: 100%;
    }
    .profile-create-div{
        width: 100%;
    }

}
